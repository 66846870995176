
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import ozonImages from "@/services/ozonImages";
import Spinner from "@/components/Spinner.vue";
import jira from '@/services/jira';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';

export default defineComponent({
  name: 'NotificationUpdateModal',
  emits: [],
  components: { BaseDatePicker, ModalWrapper },
  data: () => ({
    jiraUpdates: null as any
  }),
  methods: {
    async getUpdatesByDateRange(dateRange: any) {
      this.jiraUpdates = await jira.getUpdatesByDateRange(dateRange)
    }
  },
  async mounted() {
    this.jiraUpdates = await jira.getUpdate()
  }
})
