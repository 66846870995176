
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'DropdownOptions',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: () => '',
    },
  },
  setup(props, {emit}) {
    function choseOption(event: any) {
      const currentTarget = event.target.closest('.base-dropdown-options__item')
      if (currentTarget && currentTarget.dataset && currentTarget.dataset.key) {
        const index = currentTarget.dataset.key
        if (index !== -1) {
          emit('pickedOption', props.options[index], index)
        }
      }
    }

    return {
      choseOption,
    };
  }
})
