
import { defineComponent } from 'vue';
import deviceMixin from '@/mixins/deviceMixin';

export default defineComponent({
  name: 'BaseDropdown',
  mixins: [deviceMixin],
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    tabIndex: {
      type: String,
      default: '1'
    }
  },
  data: () => ({
    isOpen: false as boolean,
  }),
  refs: {
    baseDropdownContent: HTMLElement,
  },
  methods: {
    changeDropdownState(event: any) {
      if (!this.disabled) {
        if (this.isOpen) {
          this.close()
        } else {
          this.open()
        }
      }
    },
    close(event?: any) {
      if (event && event.relatedTarget) {
        if (event.relatedTarget.classList.contains('search-dropdown-options__input') || event.target.classList.contains('search-dropdown-options__input')) {
          return
        }
      }

      document.removeEventListener('click', this.open);
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
      document.addEventListener("click", this.open);
    }
  }
})
