
import { defineComponent, onMounted } from 'vue';
import BaseHeader from '@/components/layout/BaseHeader.vue'
// import snow from '@/components/snow'
import MessageModal from '@/components/modals/MessageModal.vue'

export default defineComponent({
  name: 'BaseLayout',
  components: {
    BaseHeader,
    MessageModal
  },
  setup() {
    // onMounted(() => {
    //   snow()
    // })
  }
})
