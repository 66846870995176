import {httpClient} from "@/httpClient";

function getUpdate() {
  return httpClient.get(`/jira/${localStorage.getItem('cid')}`).then((response) => response.data)
}

function getUpdatesByDateRange(dateRange: {start: Date, end: Date}) {
  return httpClient.post(`/jira/${localStorage.getItem('cid')}`, dateRange).then((response) => response.data)
}

export default {
  getUpdate,
  getUpdatesByDateRange
}
