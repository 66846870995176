
import { defineComponent } from 'vue';
import BaseDropdown from '@/components/dropdown/BaseDropdown.vue';
import Trigger from '@/components/dropdown/Trigger.vue';
import DropdownOptions from '@/components/dropdown/DropdownOptions.vue';
import routerService from '@/services/routerService';

export default defineComponent({
  name: 'MenuItemDropdown',
  components: {
    BaseDropdown,
    Trigger,
    DropdownOptions,
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    activeClass: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => ''
    },
    item: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    currentActiveClass() {
      const classes = ['menu-item-dropdown__title'];

      if (this.activeClass) {
        classes.push('menu-item-dropdown__title-active');
      }

      return classes;
    }
  },
  methods: {
    setActiveMenu() {
      if (this.item && this.item.route) {
        this.$emit('setActive', this.item)
      }
    },
    setOption(option: any) {
      if (String(this.$route.name).includes('cars')) {
        if (option.vehicle === 'brands-models') {
          this.$router.push('brands')
        } else if (option.vehicle === 'commodity-groups') {
          this.$router.push('commodity-groups')
        } else if (option.vehicle === 'cross-brands') {
          this.$router.push('cross-brands')
        } else {
          routerService.setQuery('vehicleState', option.vehicle, this.$route.query, this.$router)
        }
      } else if (String(this.$route.name).includes('price')) {
        if (option.key === 'gbs' || option.key === 'rrc') {
          this.$router.push(option.key)
        }
        if (option.key === 'lists') {
          let element = document.getElementById(`modal-price-list`)!
          element.style.display = 'flex'
        } else {
          let element = document.getElementById(`modal-price-list`)!
          element.style.display = 'none'
        }
        if (option.key === 'prices-list') {
          this.$router.push('prices-list')
        }
      } else if (this.$route.name === 'certificates') {
        this.$router.push(option.route)
      }

      if (option.key === 'search-vehicles-by-vin') {
        this.$router.push('/search-vehicles-by-vin')
      }
      if (option.key === 'search-articles-by-vin') {
        this.$router.push('/search-articles-by-vin')
      }

      if (option.key === 'current-certificates' || option.key === 'requests-certifications' || option.key === 'certificates-product-groups' || option.key === 'certificate-layouts-requests') {
        this.$router.push(option.route)
      }

      const refDropdown = this.$refs.menuItemDropdown as InstanceType<typeof BaseDropdown>;
      refDropdown.close()
    }
  },
})
