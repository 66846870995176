
import { defineComponent, ref, computed, onMounted, onUnmounted, nextTick, ComputedRef } from 'vue';
import {useStore} from '@/store'
import {useRoute} from 'vue-router';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {AuthenticationActionTypes} from '@/store/modules/authentication/action-types';
import {AuthenticationMutationTypes} from '@/store/modules/authentication/mutation-types';
import router from '@/router';
import articles from '@/services/articles';
import debounce from "@/components/debounce";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import SmallSpinner from '@/components/SmallSpinner.vue';
import {Status, StatusType} from "@/components/types/enums";
import NotificationIcon from '@/components/icons/NotificationIcon.vue';
import NotificationUpdateModal from '@/components/modals/NotificationUpdateModal.vue';
import deviceMixin from '@/mixins/deviceMixin';
import BaseDropdown from '@/components/dropdown/BaseDropdown.vue';
import HeaderNavigation from '@/components/bars/HeaderNavigation.vue';
import EditorArticleModal from '@/components/modals/EditorArticlesModal.vue';
import { EnvKey } from '@/models/GlobalModel';
import globalMixin from '@/mixins/globalMixin';

export default defineComponent({
  name: 'BaseHeader',
  mixins: [deviceMixin],
  components: {
    HeaderNavigation,
    NotificationUpdateModal,
    NotificationIcon,
    BaseIcon,
    SmallSpinner,
    BaseDropdown,
    EditorArticleModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const error = ref('' as string);
    const dropdownData = ref([['Выход']]);
    const isDropdownReveled = ref(false as boolean);
    const searchResult = ref([]);
    const isOpenMobileNavigation = ref(false as boolean)

    const showCertificatePages = computed(() => store.getters.getAllPermissions.certificates[store.getters.getUserRole])
    const nodeEnv: ComputedRef<any> = computed(() => process.env.NODE_ENV as EnvKey)
    const isDemo = globalMixin.computed.isDemo()

    let menu = ref([
      {key: 'ARTICLE', name: 'Артикулы', route: '/search?search=articles'},
      // {
      //   key: 'VIN',
      //   name: 'VIN',
      //   route: null,
      //   dropdownOptions: [
      //     {
      //       value: 'Поиск ТС по VIN',
      //       key: 'search-vehicles-by-vin'
      //     },
      //     {
      //       value: 'Поиск артикулов по VIN',
      //       key: 'search-articles-by-vin'
      //     },
      //   ]},
      {key: 'DEVELOPMENT_SHEETS', name: 'Листы освоения', route: '/sheets'},
      {
        key: 'PRICE',
        name: 'Анализ цен',
        route: '/prices',
        dropdownOptions: [
          {
            value: 'Поиск',
            key: 'search'
          },
          {
            value: 'Листы проценки',
            key: 'lists'
          },
          {
            value: 'Цены производителей',
            key: 'prices-list'
          },
          ...(store.getters.getAllPermissions.steal_info_gbs[store.getters.getUserRole] && !isDemo
            ? [{ value: 'Проценка GBS', key: 'gbs' }]
            : []),
          ...(store.getters.getAllPermissions.rrc[store.getters.getUserRole]
              ? [{ value: 'Соблюдение РРЦ', key: 'rrc' }]
              : []),
        ]
      },
      {
        key: 'VEHICLE',
        name: store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole] ? 'Каталоги' : 'Каталог ТС',
        route: store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole] ? '/cars?vehicleState=all' : '/cars',
        dropdownOptions: [
          {
            value: 'Каталог ТС',
            vehicle: 'all'
          },
          {
            value: 'Каталоги товаров',
            vehicle: 'commodity-groups'
          },
          {
            value: 'Каталог брендов',
            vehicle: 'cross-brands'
          }
        ]
      },
      {key: 'EXPORT', name: 'Кросс-лист', route: '/export-crosses'},
      {key: 'REPORT', name: 'Отчеты', route: '/reports'},
      {key: 'UPLOAD', name: 'Загрузки', route: '/upload'},
      ...(showCertificatePages.value
        ? [{
          key: 'CERTIFICATES',
          name: 'Сертификаты',
          route: null,
          dropdownOptions: [
            {
              value: 'Актуальные документы по сертификации',
              key: 'current-certificates',
              route: '/current-certificates'
            },
            {
              value: 'Заявки',
              key: 'requests-certifications',
              route: '/request-certificates'
            },
            {
              value: 'Товарные группы',
              key: 'certificates-product-groups',
              route: '/certificates-product-groups'
            },
            {
              value: 'Журнал заявок',
              key: 'certificate-layouts-requests',
              route: '/certificate-layouts-requests'
            },
          ]
        }]
        : []),
    ]) as any;

    const user = computed(() => store.getters.getLoggedUser);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const showSearchBar = computed(() => route.name !== 'search');
    const isLoading = ref(false as boolean);

    const activeClass = computed(() => {
      return (item: string) => {
        let articles = ['search', 'articles', 'article'];
        let sheets = ['development-sheets', 'articles-sheet', 'create-sheet'];
        let prices = [ 'price_search', 'price', 'price_article', 'price_sheet', 'price_list', 'prices_list' ];
        let vehicles = [
          'cars_search',
          'cars_brands',
          'cars_models',
          'vehicles',
          'vehicle',
          'brands',
          'brand-models',
          'commodity-groups',
          'commodity-groups-param',
          'cross-brands',
        ];
        let vin = ['search-vehicles-by-vin', 'search-articles-by-vin']
        let cross = ['cross-export'];
        if (item === 'ARTICLE' && articles.includes(String(route.name))) return true;
        if (item === 'VEHICLE' && vehicles.includes(String(route.name))) return true;
        if (item === 'PRICE' && prices.includes(String(route.name))) return true;
        if (item === 'EXPORT' && cross.includes(String(route.name))) return true;
        if (item === 'REPORT' && route.name === 'reports') return true;
        if (item === 'VIN' && vin.includes(String(route.name))) return true;
        return !!(item === 'DEVELOPMENT_SHEETS' && sheets.includes(String(route.name)))
      }
    });

    const getStatusArticle = computed(() => {
      return (item: any) => {
        if (item && item.status) {
          return `Статус: ${Status[item.status as StatusType]}`
        }
      }
    })

    function setActiveMenu(item: { [x: string]: string } = {}) {
      router.push(item.route)
    }

    function setDefaultData() {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid && !user.value) store.commit(AuthenticationMutationTypes.SET_USER, JSON.parse(loggedUser));
      if (loggedUser && cid) document.addEventListener('click', clickOutside, false)

      if (!store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole]) {
        const indexOptionVehicleDropdown = menu.value.findIndex((el: any) => el.key === 'VEHICLE')

        menu.value[indexOptionVehicleDropdown] = {
          ...menu.value[indexOptionVehicleDropdown],
          dropdownOptions: [
            {
              value: 'Каталог товаров',
              vehicle: 'commodity-groups',
            }
          ]
        }
      }

      if (!store.getters.getAllPermissions.create_prices_list[store.getters.getUserRole]) {
        menu.value = menu.value.filter((option: any) => {
          return option.key !== 'PRICE'
        })
      }

      if (!store.getters.getAllPermissions.search_mastering_sheet[store.getters.getUserRole]) {
        menu.value = menu.value.filter((option: any) => {
          return option.key !== 'DEVELOPMENT_SHEETS'
        })
      }

      if (!store.getters.getAllPermissions.export_crosses[store.getters.getUserRole]) {
        menu.value = menu.value.filter((option: any) => {
          return option.key !== 'EXPORT'
        })
      }
    }

    const canUserSearchArticles = computed(() => store.getters.getAllPermissions.search_article[store.getters.getUserRole])

    function clickOutside(e: any) {
      if (showSearchBar.value) {
        if (document.getElementById(`search-${getRandomId.value}`)!.contains(e.target)) {
          document.getElementById(`search-${getRandomId.value}`)!.classList.add('active-search');
          document.getElementById('search-bar')!.style.display = 'block';
          document.getElementById('search-input')!.focus()
        } else {
          document.getElementById(`search-${getRandomId.value}`)!.classList.remove('active-search');
          document.getElementById('search-bar')!.style.display = 'none';
          (document.getElementById('search-input') as HTMLInputElement).value = '';
          searchResult.value = []
        }
      }
    }

    function exit() {
      store.dispatch(AuthenticationActionTypes.LOGOUT, null)
    }

    function actionDropdown() {
      if (!isDropdownReveled.value) {
        document.addEventListener('click', close);
      } else {
        document.removeEventListener('click', close);
      }
      isDropdownReveled.value = !isDropdownReveled.value
    }

    function close() {
      document.removeEventListener('click', close);
      isDropdownReveled.value = false
    }

    async function search(item: any) {
      error.value = '';
      searchResult.value = [];
      if (item.target.value.length > 1 && item.target.value.length <= 20) {
        try {
          isLoading.value = true;
          let result = await articles.findArticleByName(item.target.value);
          if (!!result.length) searchResult.value = result.map((o: any) => o.article);
          if (!result.length) error.value = 'Данные не найдены'
        } catch (error) {
          error.value = 'Ошибка сервера';
          throw error
        }
        isLoading.value = false;
      } else {
        searchResult.value = []
      }
    }

    function setInput(item: any) {
      if (route.name === 'price') {
        router.push({name: 'price', params: {id: item.id}});
      } else if (route.params.id !== item.id) {
        router.push({
          name: 'article',
          params: {
            id: item.articleId
          },
          query: {
            tab: 'BASIC'
          }
        });
        clearState();
      }

      document.getElementById(`search-${getRandomId.value}`)!.classList.remove('active-search');
      document.getElementById('search-bar')!.style.display = 'none';
      (document.getElementById('search-input') as HTMLInputElement).value = '';
      searchResult.value = []
    }

    function clearState() {
      store.commit(CardArticleMutationTypes.CLEAR_STATE, null)
    }

    function goToSearchPage() {
      router.push('/search?search=articles')
    }

    const isAdmin = computed(() => store.getters.getAllPermissions.edit_user[store.getters.getUserRole])
    const userCanChangeStatuses = computed(() => store.getters.getAllPermissions.update_status[store.getters.getUserRole])

    function openMenu() {
      isOpenMobileNavigation.value = !isOpenMobileNavigation.value
    }

    function showNotificationUpdatesModal() {
      let element = document.getElementById(`modal-updates`)!
      element.style.display = 'flex'
    }

    function showMultipleChangeArticleStatusModal() {
      let element = document.getElementById(`modal-editor-articles`)!
      element.style.display = 'flex'
    }

    onMounted(() => {
      setDefaultData()
    });

    onUnmounted(() => {
      close()
    });

    function clickOutsideHeaderMobile(event: any) {
      if (event && event.relatedTarget && event.relatedTarget.classList.contains('base-dropdown')) {
        return
      }

      isOpenMobileNavigation.value = false
    }

    return {
      nodeEnv,
      userCanChangeStatuses,
      showMultipleChangeArticleStatusModal,
      clickOutsideHeaderMobile,
      isOpenMobileNavigation,
      openMenu,
      showNotificationUpdatesModal,
      dropdownData,
      user,
      menu,
      isDropdownReveled,
      showSearchBar,
      activeClass,
      getRandomId,
      searchResult,
      error,
      isLoading,
      setInput,
      search,
      setActiveMenu,
      actionDropdown,
      goToSearchPage,
      exit,
      isAdmin,
      canUserSearchArticles,
      getStatusArticle,
      isDemo,
      ...debounce()
    }
  }
})
