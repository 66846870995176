import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      $isDesktop: 'isDesktop',
      $isMobile: 'isMobile',
      $isTablet: 'isTablet',
    })
  },
}
