
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Trigger',
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getClass() {
      const classes = ['trigger__icon']
      if (this.isOpen) {
        classes.push('trigger__icon-turned')
      }

      if (this.active) {
        classes.push('trigger__icon-active')
      }

      return classes
    }
  },
})
