
import {defineComponent, computed} from 'vue';
import {useStore} from '@/store'

export default defineComponent({
  name: 'MessageModal',
  setup() {
    const store = useStore();
    const type = computed(() => store.getters.getTypeMessageModal);

    return {type}
  }
})
