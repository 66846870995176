
import { defineComponent } from 'vue';
import MenuItemDropdown from '@/components/dropdown/MenuItemDropdown.vue';
import router from '@/router';

export default defineComponent({
  name: 'HeaderNavigation',
  props: {
    menuOptions: {
      type: Array,
      required: true
    },
    view: {
      type: String,
      default: 'desktop'
    }
  },
  components: {
    MenuItemDropdown
  },
  data: () => ({
  }),
  computed: {
    classList(): Array<string> {
      return [
        'header-navigation',
        `header-navigation__view_${this.view}`,
      ]
    },
    activeClass() {
      return (item: any) => {
        const namesRoutes = {
          articles: ['search', 'articles', 'article'],
          sheets: ['sheets', 'development-sheets', 'articles-sheet', 'create-sheet'],
          prices: ['price_search', 'price', 'price_article', 'price_sheet', 'price_list', 'prices_list'],
          vehicles: ['cars_search', 'cars_brands', 'cars_models', 'vehicles', 'vehicle', 'brands', 'brand-models', 'commodity-groups', 'commodity-groups-param', 'cross-brands'],
          vin: ['search-vehicles-by-vin', 'search-articles-by-vin'],
          cross: ['cross-export'],
          certificates: ['certificates', 'request-certificates', 'current-certificates', 'certificates-product-groups', 'certificate-layouts-requests'],
        }

        if (item === 'ARTICLE' && namesRoutes.articles.includes(String(this.$route.name))) return true;
        if (item === 'VEHICLE' && namesRoutes.vehicles.includes(String(this.$route.name))) return true;
        if (item === 'PRICE' && namesRoutes.prices.includes(String(this.$route.name))) return true;
        if (item === 'EXPORT' && namesRoutes.cross.includes(String(this.$route.name))) return true;
        if (item === 'REPORT' && this.$route.name === 'reports') return true;
        if (item === 'UPLOAD' && this.$route.name === 'upload') return true;
        if (item === 'VIN' && namesRoutes.vin.includes(String(this.$route.name))) return true;
        if (item === 'DEVELOPMENT_SHEETS' && namesRoutes.sheets.includes(String(this.$route.name))) return true;
        if (item === 'CERTIFICATES' && namesRoutes.certificates.includes(String(this.$route.name))) return true;
      }
    }
  },
  methods: {
    setActiveMenu(item: { [x: string]: string } = {}) {
      router.push(item.route)
    }
  },
})
