
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import articles from '@/services/articles';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import logistic from '@/services/logistic';
import debounce from '@/components/debounce';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'MultipleChangeArticleStatusModal',
  components: {
    ModalWrapper,
    BaseIcon,
    BaseSelect,
    SmallSpinner,
    BaseTabs,
    SearchDropdown
  },
  data: () => ({
    eventInputRenameArticles: null as Event | null,
    articlesNamesForDelete: null as [] | null,
    formDataFile: null as any,
    selectedStatus: null as any,
    uploadLoading: false as any,
    uploadOptions: ['Смена статуса', 'Переименование', 'Удаление артикулов'],
    chosenUpload: 'Смена статуса' as string,
  }),
  setup() {
    return { ...debounce() }
  },
  methods: {
    async downloadRenameArticlesTemplate() {
      const table = await articles.renameArticlesTemplate() as any
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `rename-articles-template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadTemplate() {
      const table = await articles.updateStatusTemplate() as any
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `update-status-template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveUploadFile(event: Event) {
      this.eventInputRenameArticles = event
    },
    deleteArticle() {
      try {
        this.$store.dispatch('deleteArticles', this.articlesNamesForDelete)
        this.clearState()
        this.closeModal()
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
    updateArticlesForDeleting(event: any) {
      if (!event.target.value.length) {
        this.articlesNamesForDelete = []
      } else {
        this.articlesNamesForDelete = event.target.value.trim().split('\n')
      }
    },
    async renameArticles() {
      try {
        await this.$store.dispatch('renameArticles', this.eventInputRenameArticles?.target)
        const renameFileInput = this.$refs.renameFileInput as InstanceType<typeof HTMLInputElement> | undefined
        if (renameFileInput) renameFileInput.value = ''
        this.closeModal()
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        this.eventInputRenameArticles = null
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
    clearState() {
      const renameFileInput = this.$refs.renameFileInput as InstanceType<typeof HTMLInputElement> | undefined
      if (renameFileInput) renameFileInput.value = ''

      this.articlesNamesForDelete = []
      this.eventInputRenameArticles = null
    },
    closeModal() {
      const element = document.getElementById('modal-editor-articles')!;
      element.style.display = 'none'
    },
    async changeStatus() {
      try {
        this.uploadLoading = true
        await articles.updateMultipleStatus(this.formDataFile, this.selectedStatus)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        this.closeModal()
      } catch (e) {
        console.error(e)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      } finally {
        this.uploadLoading = false
      }
    },
    setStatus(status: any) {
      this.selectedStatus = status
    },
    parseTable(e: any) {
      const file = e.target.files;
      const formData = new FormData();
      formData.append('file', file[0]);
      this.formDataFile = formData
    }
  },
})
